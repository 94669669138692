(() => {
    const btns_promo = document.querySelectorAll('.promo-copy');

    if (btns_promo.length > 0) {
        for(const item of btns_promo){
            item.addEventListener('click', () => {
                const info_window = document.querySelector('.promocode--info');
                info_window.classList.add('show');
                const promo = item.dataset.promo;
                navigator.clipboard.writeText(promo);
                setTimeout(() => info_window.classList.remove('show'), '1500');
            })
        }
    }
})();